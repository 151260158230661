// Generated by Framer (e83dae3)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["bPv1UJx7H"];

const serializationHash = "framer-l0Ko8"

const variantClassNames = {bPv1UJx7H: "framer-v-ro7063"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, image, width, ...props}) => { return {...props, Yo7UXCjeZ: image ?? props.Yo7UXCjeZ} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;image?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, Yo7UXCjeZ, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "bPv1UJx7H", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-ro7063", className, classNames)} data-border data-framer-name={"Default"} layoutDependency={layoutDependency} layoutId={"bPv1UJx7H"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{"--border-bottom-width": "1px", "--border-color": "rgba(0, 0, 0, 0.08)", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backgroundColor: "var(--token-8c47652b-dea5-4767-a9f2-5d952dcce49a, rgb(255, 255, 255))", borderBottomLeftRadius: 20, borderBottomRightRadius: 20, borderTopLeftRadius: 20, borderTopRightRadius: 20, ...style}}><Image background={{alt: "", fit: "fit", intrinsicHeight: 2160, intrinsicWidth: 3840, pixelHeight: 2160, pixelWidth: 3840, sizes: `calc((${componentViewport?.width || "100vw"} - 50px) * 0.95)`, ...toResponsiveImage(Yo7UXCjeZ), ...{ positionX: "center", positionY: "center" }}} className={"framer-1efkbs3"} layoutDependency={layoutDependency} layoutId={"yDTOuyXKo"}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-l0Ko8.framer-1c2sjbn, .framer-l0Ko8 .framer-1c2sjbn { display: block; }", ".framer-l0Ko8.framer-ro7063 { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 40px; height: 180px; justify-content: flex-start; max-height: 180px; overflow: hidden; padding: 25px 25px 25px 25px; position: relative; width: 320px; will-change: var(--framer-will-change-override, transform); }", ".framer-l0Ko8 .framer-1efkbs3 { align-content: center; align-items: center; display: flex; flex: none; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: 132px; justify-content: center; max-height: 100%; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: 95%; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-l0Ko8.framer-ro7063, .framer-l0Ko8 .framer-1efkbs3 { gap: 0px; } .framer-l0Ko8.framer-ro7063 > * { margin: 0px; margin-bottom: calc(40px / 2); margin-top: calc(40px / 2); } .framer-l0Ko8.framer-ro7063 > :first-child, .framer-l0Ko8 .framer-1efkbs3 > :first-child { margin-top: 0px; } .framer-l0Ko8.framer-ro7063 > :last-child, .framer-l0Ko8 .framer-1efkbs3 > :last-child { margin-bottom: 0px; } .framer-l0Ko8 .framer-1efkbs3 > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } }", ".framer-l0Ko8[data-border=\"true\"]::after, .framer-l0Ko8 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 180
 * @framerIntrinsicWidth 320
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"],"constraints":[null,null,null,"180px"]}}}
 * @framerVariables {"Yo7UXCjeZ":"image"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerfhXHNd4LH: React.ComponentType<Props> = withCSS(Component, css, "framer-l0Ko8") as typeof Component;
export default FramerfhXHNd4LH;

FramerfhXHNd4LH.displayName = "Partner Logo";

FramerfhXHNd4LH.defaultProps = {height: 180, width: 320};

addPropertyControls(FramerfhXHNd4LH, {Yo7UXCjeZ: {title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerfhXHNd4LH, [])